import { UserPhone } from '@remento/types/user';
import EmailValidator from 'email-validator';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';
import { createUserPhoneSchema } from '@/utils/phone-number';

export interface ProfileEditFormInput {
  firstName: string;
  lastName: string;
  email: string;
  phone?: UserPhone | null;
}

export type ProfileEditForm = Form<ProfileEditFormInput>;

export function createProfileEditFormSchema() {
  return z.object({
    firstName: z.string().min(1, { message: 'This is a required field' }).min(1),
    lastName: z.string().min(1, { message: 'This is a required field' }).min(1),
    email: z.string().refine(EmailValidator.validate, { message: 'Enter a valid email address' }),
    phone: createUserPhoneSchema().nullish(),
  });
}

export function createProfileEditForm(defaultValues?: DeepPartial<ProfileEditFormInput>): ProfileEditForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createProfileEditFormSchema(),
  });
}
